import React, { useState } from 'react';
import {documentation} from "../assets/documentation.js"
import "./DocScreen.css";
import logo from "../assets/Redground-oldlogo.png";

const DocScreen = () => {
  const [selectedTopic, setSelectedTopic] = useState(null);

  const handleTopicClick = (topic) => {
    setSelectedTopic(topic);
  };

  const selectedContent = documentation.find((item) => item.id === selectedTopic);

  return (
    <div className="documentation">
        <div className="documentation__nav">
            <img
            src={logo}
            alt='Redground'
            />
            <p>Documentación del sistema v1.0<span>Actualizado al 27.09.2023</span></p>
        </div>

        <div className="documentation-container">
            <div className="topics-list-container">
                <ul className="topics-list">
                {documentation.map((topic) => (
                    <li key={topic.id} onClick={() => handleTopicClick(topic.id)}>
                    {topic.id}. {topic.category} : {topic.topic_name}
                    </li>
                ))}
                </ul>
            </div>
            <div className="content-container">
            <div className="content">
                {selectedContent ? (
                <div className="content__detail">
                    <h2>{selectedContent.category}</h2>
                    <h1>{selectedContent.topic_name}</h1>
                    <p>{selectedContent.topic_content}</p>
                </div>
                ) : (
                <p className="content__notopic">Select a topic to view its content.</p>
                )}
            </div>
            </div>
        </div>
    </div>
  );
};

export default DocScreen;