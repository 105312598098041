import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import HomeScreen from './screens/HomeScreen';
import DocScreen from './screens/DocScreen';

function App() {
  return (
    <div className="app">
    <Router onUpdate={() => window.scrollTo(0, 0)}>
      {
        <Routes>
          <Route exact path="/" element={<HomeScreen />} />
          <Route exact path="/doc" element={<DocScreen />} />
        </Routes>
      }
      </Router>
    </div>
  );
}

export default App;